<template>
  <div class="settlement-recode-wrapper">
    <van-field
	  readonly
      class="my_insured_field"
      v-model="settlementInfo.type"
      name="settlementId"
      label="理赔保单"
      placeholder="请选择需要赔的保单"
      label-class="my_insured_label"
      @click="settlementPopup = true"
      @blur="settlementPopup = false"
    />
    <van-popup v-model="settlementPopup" position="bottom" class="tip_pop">
      <van-picker
        title="请选择需要赔的保单"
        :columns="columns"
        show-toolbar
        @confirm="onBaoConfirm"
        @cancel="settlementPopup = false"
      />
    </van-popup>
    <div class="settlement-apply-info">请填写正确信息</div>
    <van-field
      class="my_insured_field"
      v-model="settlementInfo.settlementName"
      name="insuredName"
      label="姓名"
      placeholder="请输入被保人姓名"
      label-class="my_insured_label"
      required
    />

    <van-field
      class="my_insured_field"
      v-model="settlementInfo.settlementTel"
      name="settlementTel"
      label="手机号码"
      placeholder="请输入手机号码"
      label-class="my_insured_label"
      required
    />

    <van-field
      v-model="settlementInfo.settlementMessage"
      rows="2"
      label="理赔详情"
      type="textarea"
      maxlength="5000"
      placeholder="请输入理赔详情"
      show-word-limit
    />

    <van-button type="danger" class="settlement-btn" @click="settleApply"
      >立即提交</van-button
    >
    <div class="settlement-recode-kefu">
      <!-- <div class="settlement-recode-kefu-item">
        <img
          class="settlement-recode-img"
          src="../../../assets/images/lipei-wechat.png"
        />
        <div class="kefu-item-text">在线理赔</div>
      </div> -->
      <!-- <div class="settlement-recode-line"></div> -->
      <div class="settlement-recode-kefu-item">
        <a href="tel:400-099-6778">
          <img
            class="settlement-recode-img"
            src="../../../assets/images/lipei-phone.png"
          />
          <div class="kefu-item-text">电话理赔</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import './SettlementApply.less'
import { Picker, Field, Popup, Button, Notify } from 'vant'
import { saveClaim, getMyOrders } from '../../../api/api';

export default {
  name: 'SettlementApply',
  data() {
    return {
      columns: [],
      settlementInfo: {
        type: '',
        settlementName: '',
        settlementTel: '',
        settlementMessage: '',
        policyNo: '',
        userCode: ''
      },
      settlementPopup: false,
      baoList: []
    }
  },
  components: {
    "van-picker": Picker,
    "van-field": Field,
    "van-popup": Popup,
    "van-button": Button
  },
  mounted() {
    this.getMyOrders();
  },
  methods: {
    async settleApply() {
      const errMsg = this.verifyFormData();
      if (errMsg) {
        // 校验投保人
        Notify(errMsg);
      } else {
        const data = await saveClaim({
          "memo": this.settlementInfo.settlementMessage,
          "name": this.settlementInfo.settlementName,
          "openid": JSON.parse(localStorage.getItem('loginInfo')).openId,
          "policyNo": this.settlementInfo.policyNo,
          "telephone": this.settlementInfo.settlementTel,
          "unionid": JSON.parse(localStorage.getItem('loginInfo')).unitId,
          "userCode": this.settlementInfo.inviteCode
        });
        if (data.code == 200) {
          Notify('申请成功');
          this.settlementInfo = {
            type: '',
            settlementName: '',
            settlementTel: '',
            settlementMessage: '',
            policyNo: '',
            userCode: ''
          }
        }else{
			const errMsg = data.message?data.message:'理赔申请失败'
			Notify(errMsg);
		}
      }
    },

    // 投保人表单校验
    verifyFormData() {
      // 个人投保校验规则
      let verifyRules = [];
      verifyRules = [
        {
          errMsg: "请输入姓名",
          value: this.settlementInfo.settlementName,
          type: "settlementName",
        },
        {
          errMsg: "请选择保单",
          value: this.settlementInfo.policyNo,
          verifyMsg: "身份证格式不正确",
          type: "policyNo",
        },
        {
          errMsg: "请输入手机号",
          value: this.settlementInfo.settlementTel,
          verifyMsg: "手机号格式不正确",
          type: "settlementTel",
        },
      ];
      return this.getErrorMsg(verifyRules);
    },
    // 错误信息提示
    getErrorMsg(verifyRules) {
      let errorArray = [];
      verifyRules.map((item) => {
        if (!item.value) {
          errorArray.push(item.errMsg);
        }
      });
      if (!errorArray.length) return false;
      return errorArray[0];
    },
    onBaoConfirm(item) {
	  this.settlementPopup = false
      this.settlementInfo.type = item;
      this.settlementInfo.policyNo = this.baoList.filter(res => {
        return res.policyNo === item.slice(0, item.indexOf('(“'))
      })[0].policyNo;
      this.settlementInfo.inviteCode = this.baoList.filter(res => {
        return res.policyNo === item.slice(0, item.indexOf('(“'))
      })[0].inviteCode;
    },
    async getMyOrders() {
      console.log(JSON.parse(localStorage.getItem('loginInfo')));
      const { data } = await getMyOrders({
        "openId": JSON.parse(localStorage.getItem('loginInfo')).openId,
        "unitId": JSON.parse(localStorage.getItem('loginInfo')).unitId
      });
      console.log(data);
      this.baoList = data;
      data.filter(x=>x.status == 'AVAILABLE' || x.status == 'CORRECTED').forEach(res => {
        this.columns.push(`${res.policyNo}(${res.productName})`)
      })
    }
  }
}
</script>